import React from "react";

export default function Policy({ Title, space }) {
  return (
    <>
      <section className={`gray-light-bg  ${space ? "ptb-100" : "pb-60"}`}>
        <div className="container">
          {Title ? (
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading mb-5 text-center">
                  <h2>Policy summary</h2>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row">
            <div className="col-md-6">
              <div className="single-faq">
                <p>
                  www.schemesbook.com & and its mobile application platform
                  Schemes Book (each a “Platform”) is a platform which publishes
                  content for the users and also allows the users to post
                  content which is primarly a B2B platform. Schemes Book
                  recognizes the importance of privacy as well as theimportance
                  of maintaining the confidentiality of personal information.{" "}
                  <br />
                  <br /> All capitalized terms not defined in this document
                  shall have the meanings ascribed to them in the Terms of Use
                  of the Platform, which can be found here. Contracting entity
                  shall be S R R Enterprises (herein after referred to as
                  ‘www.schemesbook.com ’ or ‘us’ or ‘our’).
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-faq">
                <p>
                  This Privacy Policy applies to all products and services
                  provided by and sets out how www.schemesbook.com may
                  collect,use and disclose information in relation to Users of
                  the Platform. <br /> <br /> User may use www.schemesbook.com
                  services and products via a mobile device either through
                  mobile applications or mobile optimized websites. This Privacy
                  Policy also applies to such use of www.schemesbook.com’s
                  services and products.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-9 col-lg-8">
              <div className="section-heading mb-5 text-center">
                <h2>Personal Data</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="single-faq">
                <p>
                  • Geographic Position <br /> • Name, Address & Contact
                  Information <br /> • Government IDs like GSTIN <br /> Payment
                  information like credit card numbers, bank account numbers etc
                  are used for third party payment gateways but not collected by
                  us in any fashion. <br /> Full policy <br /> Data Controller
                  and Owner Types of Data collected <br /> Among the types of
                  Personal Data that this Application collects, by itself or
                  through third parties, there are: , <br /> Camera permission,
                  Precise location permission , Approximate location permission
                  , Storage permission, geographic position, <br /> Cookies and
                  Usage Data. <br /> <br /> The Data processing is carried out
                  using computers and/or IT enabled tools, following
                  organizational procedures and modes strictly related to the
                  purposes indicated. <br /> In addition to the Data Controller,
                  in some cases, the Data may be accessible to certain types of
                  persons in charge, involved with the operation of the site
                  (administration, sales, marketing, legal, system
                  administration) <br /> or external parties (such as third
                  party technical service providers, mail carriers, hosting
                  providers, IT companies, communications agencies) appointed,
                  if necessary, as Data Processors by the Owner. The updated
                  list of these parties may be requested from the Data
                  Controller at any time.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-faq">
                <p>
                  Other Personal Data collected may be described in other
                  sections of this privacy policy or by dedicated explanation
                  text contextually with the Data collection. <br /> <br />
                  The Personal Data may be freely provided by the User, or
                  collected automatically when using this Application. <br />{" "}
                  Any use of Cookies - or of other tracking tools - by this
                  Application or by the owners of third party services used by
                  this Application, unless stated otherwise, serves to identify
                  Users and remember their preferences, for the sole purpose of
                  providing the service required by the User. <br /> <br />{" "}
                  Failure to provide certain Personal Data may make it
                  impossible for this Application to provide its services.{" "}
                  <br /> Users are responsible for any Personal Data of third
                  parties obtained, published or shared through this Application
                  and confirm that they have the third party's consent to
                  provide the Data to the Owner. <br /> Mode and place of
                  processing the Data Methods of processing The Data Controller
                  processes the Data of Users in a proper manner and shall take
                  appropriate security measures to prevent unauthorized access,
                  disclosure, modification, or unauthorized destruction of the
                  Data.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-9 col-lg-8">
              <div className="section-heading mb-5 text-center">
                <h2>Place & Retention time</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="single-faq">
                <p>
                  The Data is processed at the Data Controller's operating
                  offices and in any other places where the parties involved
                  with the processing are located. For further information,
                  please contact the Data Controller.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-faq">
                <p>
                  The Data is kept for the time necessary to provide the service
                  requested by the User, or stated by the purposes outlined in
                  this document, and the User can always request that the Data
                  Controller suspend or remove the data.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-9 col-lg-8">
              <div className="section-heading mb-5 text-center">
                <h2>The use of the collected Data</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="single-faq">
                <p>
                  The Data concerning the User is collected to allow the Owner
                  to provide its services, as well as for the following
                  purposes: <br /> Device permissions for Personal Data access,
                  Location-based interactions and Interaction with external
                  social networks and platforms.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-faq">
                <p>
                  The Personal Data used for each purpose is outlined in the
                  specific sections of this document.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-9 col-lg-8">
              <div className="section-heading mb-5 text-center">
                <h2>Device permissions for Personal Data access</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="single-faq">
                <p>
                  This Application requests certain permissions from Users that
                  allow it to access the User's device Data as described below.
                  <br /> <br /> By default, these permissions must be granted by
                  the User before the respective information can be accessed.{" "}
                  <br />
                  Once the permission has been given, it can be revoked by the
                  User at any time. <br /> In order to revoke these permissions,
                  Users may refer to the device settings or contact the Owner
                  for support at the contact details provided in the present
                  document. <br /> The exact procedure for controlling app
                  permissions may be dependent on the User's device and
                  software. Please note that the revoking of such permissions
                  might impact the proper functioning of this Application.{" "}
                  <br /> If User grants any of the permissions listed below,
                  these respective Personal Data may be processed (i.e accessed
                  to, modified or removed) by this Application. <br /> <br />{" "}
                  <span style={{ fontWeight: "900" }}>
                    Personal Data collected:
                  </span>{" "}
                  <br /> Approximate location permission, Camera permission, ,
                  Precise location permission and Storage permission.
                  Interaction with external social networks and platforms This
                  type of service allows interaction with social networks or
                  other external platforms directly from the pages of this
                  Application. The interaction and information obtained through
                  this Application are always subject to the User’s privacy
                  settings for each social network.
                  <br /> <br />
                  This type of service might still collect traffic data for the
                  pages where the service is installed, even when Users do not
                  use it. Twitter Tweet button and social widgets (Twitter,
                  Inc.) The Twitter Tweet button and social widgets are services
                  allowing interaction with the Twitter social network provided
                  by Twitter, Inc. Personal Data collected: Cookies and Usage
                  Data. Place of processing: US – Privacy Policy Location-based
                  interactions Geolocation (This Application) This Application
                  may collect, use, and share User location Data in order to
                  provide location-based services. <br /> <br /> Most browsers
                  and devices provide tools to opt out from this feature by
                  default. If explicit authorization has been provided, the
                  User’s location data may be tracked by this Application.
                  Personal Data collected: geographic position. Additional
                  information about Data collection and processing Legal action
                  The User's Personal Data may be used for legal purposes by the
                  Data Controller, in Court or in the stages leading to possible
                  legal action arising from improper use of this Application or
                  the related services. The User declares to be aware that the
                  Data Controller may be required to reveal personal data upon
                  request of public authorities.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-faq">
                <p>
                  If User grants any of the permissions listed below, these
                  respective Personal Data may be processed (i.e accessed to,
                  modified or removed) by this Application. <br /> <br />
                  <span style={{ fontWeight: "900" }}>
                    {" "}
                    Approximate location permission{" "}
                  </span>{" "}
                  <br /> Used for accessing the User's approximate device
                  location. This Application may collect, use, and share User
                  location Data in order to provide location-based services.{" "}
                  <br /> <br />
                  <span style={{ fontWeight: "900" }}>
                    {" "}
                    Camera permission{" "}
                  </span>{" "}
                  <br /> Used for accessing the camera or capturing images and
                  video from the device. Used for accessing profiles on the
                  User's device, including the changing of entries. <br />{" "}
                  <br />
                  <span style={{ fontWeight: "900" }}>
                    Precise location permission
                  </span>{" "}
                  <br /> Used for accessing the User's precise device location.
                  This Application may collect, use, and share User location
                  Data in order to provide location-based services. <br />{" "}
                  <br />
                  <span style={{ fontWeight: "900" }}>
                    Storage permission
                  </span>{" "}
                  <br />
                  Used for accessing shared external storage, including the
                  reading and adding of any items. Detailed information on the
                  processing of Personal Data Personal Data is collected for the
                  following purposes and using the following services: <br />{" "}
                  Device permissions for Personal Data access This Application
                  requests certain permissions from Users that allow it to
                  access the User's device Data as described below. <br />{" "}
                  Device permissions for Personal Data access (This Application)
                  This Application requests certain permissions from Users that
                  allow it to access the User's device Data as summarized here
                  and described within this document. <br /> <br />{" "}
                  <span style={{ fontWeight: "900" }}>
                    Additional information about User's Personal Data{" "}
                  </span>{" "}
                  <br />
                  In addition to the information contained in this privacy
                  policy, this Application may provide the User with additional
                  and contextual information concerning particular services or
                  the collection and processing of Personal Data upon request.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-9 col-lg-8">
              <div className="section-heading mb-5 text-center">
                <h2>System logs and maintenance</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="single-faq">
                <p>
                  <span style={{ fontWeight: "900" }}>
                    For operation and maintenance purposes:
                  </span>{" "}
                  <br />
                  this Application and any third party services may collect
                  files that record interaction with this Application (System
                  logs) or use for this purpose other Personal Data (such as IP
                  Address). Information not contained in this policy More
                  details concerning the collection or processing of Personal
                  Data may be requested from the Data Controller at any time.
                  Please see the contact information at the beginning of this
                  document. <br /> <br /> Changes to this privacy policy The
                  Data Controller reserves the right to make changes to this
                  privacy policy at any time by giving notice to its Users on
                  this page. It is strongly recommended to check this page
                  often, referring to the date of the last modification listed
                  at the bottom.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-faq">
                <p>
                  <span style={{ fontWeight: "900" }}>The rights of Users</span>{" "}
                  <br />
                  Users have the right, at any time, to know whether their
                  Personal Data has been stored and can consult the Data
                  Controller to learn about their contents and origin, to verify
                  their accuracy or to ask for them to be supplemented,
                  cancelled, updated or corrected, or for their transformation
                  into anonymous format or to block any data held in violation
                  of the law, as well as to oppose their treatment for any and
                  all legitimate reasons. Requests should be sent to the Data
                  Controller at the contact information set out above. This
                  Application does not support “Do Not Track” requests. To
                  determine whether any of the third party services it uses
                  honor the “Do Not Track” requests, please read their privacy
                  policies. <br /> <br /> If a User objects to any of the
                  changes to the Policy, the User must cease using this
                  Application and can request that the Data Controller remove
                  the Personal Data. Unless stated otherwise, the then-current
                  privacy policy applies to all Personal Data the Data
                  Controller has about Users.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-9 col-lg-8">
              <div className="section-heading mb-5 text-center">
                <h2>Definitions and legal references</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="single-faq">
                <p>
                  <span style={{ fontWeight: "900" }}>
                    Personal Data (or Data)
                  </span>{" "}
                  <br />
                  Any information regarding a natural person, a legal person, an
                  institution or an association, which is, or can be,
                  identified, even indirectly, by reference to any other
                  information, including a personal identification number.{" "}
                  <br /> <br />
                  <span style={{ fontWeight: "900" }}>User</span> <br />
                  The individual using this Application, which must coincide
                  with or be authorized by the Data Subject, to whom the
                  Personal Data refers. Data Subject The legal or natural person
                  to whom the Personal Data refers. Data Processor (or Data
                  Supervisor) The natural person, legal person, public
                  administration or any other body, association or organization
                  authorized by the Data Controller to process the Personal Data
                  in compliance with this privacy policy. <br /> <br />
                  <span style={{ fontWeight: "900" }}>Cookies</span> <br />
                  Small piece of data stored in the User's device.
                  <br /> <br />
                  <span style={{ fontWeight: "900" }}>
                    NLC Calculations:
                  </span>{" "}
                  <br />
                  All Calculations are based on Schemes given by the USERS, no
                  calculations can be challenged as these are as per the
                  software logic and may vary for each user or location. These
                  may or may not be exact, can be used only as a REFERENCE . The
                  Scheme letters which are updated here are crowd sourced and we
                  do not take any accountability for the authenticity, how ever
                  we take good care to ensure the relevant
                  schemes and price lists
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-faq">
                <p>
                  <span style={{ fontWeight: "900" }}>Usage Data</span> <br />
                  Information collected automatically from this Application (or
                  third party services employed in this Application), which can
                  include: the IP addresses or domain names of the computers
                  utilized by the Users who use this Application, the URI
                  addresses (Uniform Resource Identifier), the time of the
                  request, the method utilized to submit the request to the
                  server, the size of the file received in response, the
                  numerical code indicating the status of the server's answer
                  (successful outcome, error, etc.), the country of origin, the
                  features of the browser and the operating system utilized by
                  the User, the various time details per visit (e.g., the time
                  spent on each page within the Application) and the details
                  about the path followed within the Application with special
                  reference to the sequence of pages visited, and other
                  parameters about the device operating system and/or the User's
                  IT environment. <br /> <br />{" "}
                  <span style={{ fontWeight: "900" }}>
                    Data Controller (or Owner)
                  </span>{" "}
                  <br />
                  The natural person, legal person, public administration or any
                  other body, association or organization with the right, also
                  jointly with another Data Controller, to make decisions
                  regarding the purposes, and the methods of processing of
                  Personal Data and the means used, including the security
                  measures concerning the operation and use of this Application.
                  The Data Controller, unless otherwise specified, is the Owner
                  of this Application.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
