import React from "react";

const Promo = () => {
  return (
    <>
      <section className="promo-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                <h3>Why Retailers Loves SchemesBook?</h3>
              </div>
            </div>
          </div>
          <div className="row equal">
            <div className="col-md-4 col-lg-3">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-vector text-white"></span>
                </div>
                <h5>Updated Schemes</h5>
                <p>
                  Real time updates of Schemes of all brands model wise to benefit
                  retailers to increase sales and profitability.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-lock text-white"></span>
                </div>
                <h5>Price DROPS</h5>
                <p>
                  All Price DROPS updated instantly to benefit the retailer
                  to take advantage.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-eye text-white"></span>
                </div>
                <h5>Social media Sharing</h5>
                <p>
                  A huge library of images/videos for promotions on social media
                  with custom designs specific to each retailer.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <span className="ti-eye text-white"></span>
                </div>
                <h5>Calculator</h5>
                <p>
                  All Scheme calculations to benefit the retailer to instantly
                  know earnings and track disbursements <br /> by suppliers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Promo;
