import React from "react";

export default function Refund({ Title, space }) {
  return (
    <>
      <section className={`gray-light-bg  ${space ? "ptb-100" : "pb-60"}`}>
        <div className="container">
          {Title ? (
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading mb-5 text-center">
                  <h2>Policy summary</h2>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row">
            <div className="col-md-6">
              <div className="single-faq">
                <p>
                  At Schemes Book, we strive to ensure customer satisfaction
                  with every purchase. We understand that sometimes a product
                  may not meet your expectations. Therefore, we offer refunds
                  under the following terms and conditions:
                </p>
                <p>
                  <span style={{ fontWeight: "900" }}>Refund Period:</span> We
                  offer refunds on products within 7 days of the purchase date.
                </p>
                <p>
                  <span style={{ fontWeight: "900" }}>Eligibility:</span> To be
                  eligible for a refund, you must be a registered user and
                  subscriber of Schemes Book.
                </p>
                <p>
                  <span style={{ fontWeight: "900" }}>Contact:</span> If you are
                  not satisfied with your purchase and wish to initiate a
                  refund, please contact our customer service team at
                  contactus@schemesbook.com or call us at 9036770772.
                </p>
                <p>
                  <span style={{ fontWeight: "900" }}>Proof of Purchase: </span>{" "}
                  To complete your refund, we require a receipt or proof of
                  purchase. Please provide this information when contacting our
                  customer service team.
                </p>
                <p>
                  <span style={{ fontWeight: "900" }}>Refund Process:</span>
                  Once your return request is received, we will send you an
                  email to acknowledge that we have received your request. A
                  credit will automatically be applied to your original method
                  of payment within TWO working days after the acknowledgment
                  email is sent.
                </p>{" "}
                <p>
                  If you have any further questions regarding our refund policy,
                  please do not hesitate to contact our customer service team.
                  Your satisfaction is our priority.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
