import React from "react";

export default function FeatureImgTwo() {
  return (
    <>
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h2>
                  Use Your Android Device <br />
                  to Manage Everything
                </h2>

                <div className="single-feature mb-4 mt-5">
                  <div className="icon-box-wrap d-flex align-items-center mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-1.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                      NLC - Net landing Cost of each model calculated and
                      updated real time considering all schemes and promotions.
                    </p>
                  </div>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-2.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                      Schemes: Model/Brand wise repository of all scheme
                      communication by Brands.
                    </p>
                  </div>
                  <p></p>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-3.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                      Price Drops: Model/Brand wise repository of all Price
                      DROPS declared along with a DROP Tracker for Claims.
                    </p>
                  </div>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src="assets/img/edd6.jpg"
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
